import { styled } from 'styled-components';
import { ReactComponent as IconArrow } from 'assets/img/IconArrow.svg';

import colors from 'global_styling/colors';

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  li {
    cursor: pointer;
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  flex-direction: column;
  z-index: 1;
  background: #f8f9fd;
  min-width: 60px;
  padding: 12px 16px;
  margin-top: 10px;
  z-index: 10;

  ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  li {
    line-height: 30px;
  }
  &.open {
    display: block;
  }
  button {
    border: none;
    cursor: pointer;
    background: none;
    &:hover {
      color: ${colors.TEAL};
    }
  }
`;

const Arrow = styled(IconArrow)<{ 'data-is-open': boolean }>`
  display: inline-block;
  transform: ${(props) => (props['data-is-open'] ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
  margin-left: 6px;
`;

export const FlagIcon = styled.img`
  width: 24px;
  height: 18px;
  vertical-align: middle;
  margin-right: 4px;
`;

export const SwitcherButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SelectedLanguage = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export { DropdownContainer, DropdownContent, Arrow };
