import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Импорт LanguageDetector
import enTranslations from './locales/en/translation.json';
import ruTranslations from './locales/ru/translation.json';
import kkTranslations from './locales/kz/translation.json';

const savedLanguage = localStorage.getItem('language') || navigator.language.split('-')[0] || 'kk'; // Обрезаем суффикс

const availableLanguages = ['en', 'kk', 'ru']; // Список поддерживаемых языков

const detectedLanguage = availableLanguages.includes(savedLanguage) ? savedLanguage : 'kk'; // Проверяем, поддерживается ли язык

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      kk: { translation: kkTranslations },
      ru: { translation: ruTranslations },
    },
    lng: detectedLanguage, // Используем определённый язык
    fallbackLng: 'kk', // Резервный язык
    interpolation: { escapeValue: false },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
  });

export default i18n;
