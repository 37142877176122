import styled from 'styled-components';
import colors from 'global_styling/colors';
import InputMask from 'react-input-mask';

export const FeedbackBlockBox = styled.div`
  display: flex;
  align-items: stretch; /* Растягиваем блоки до одинаковой высоты */
  margin: 150px 0px;
  gap: 30px;
  width: 100%;
  @media (max-width: 1120px) {
    flex-direction: column;
    margin: 50px 0px;
    width: 100%;
  }
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 40%;
  flex: 1; /* Заставляем блок растягиваться по высоте */
  @media (max-width: 1120px) {
    width: 100%;
  }
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  background: #5784f7;
  justify-content: center;
  gap: 24px;
  height: 100%; /* Занимаем доступную высоту */

  border-radius: 24px;
  @media (max-width: 1120px) {
    width: 100%;

    padding: 24px;
  }
  button {
    max-width: 180px;
  }
`;

export const LeftBlockContact = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  border: 1px solid #5784f7;
  background: #fff;
`;

export const BlockContact = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    color: #292d32;
    font-size: 16px;
    h5 {
      font-weight: 600;
    }
    p {
      font-weight: 400;
    }
  }
`;

export const LeftBlockText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  color: #fff;

  h3 {
    font-size: 32px;
    font-weight: 700;
    @media (max-width: 1120px) {
      font-size: 20px;
    }
  }
  p {
    font-size: 16px;
    font-weight: 400;
    @media (max-width: 1120px) {
      font-size: 14px;
    }
  }
`;

export const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 40px;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  border-radius: 24px;
  border: 1px solid #5784f7;
  flex: 1; /* Заставляем блок растягиваться по высоте */

  background: #fff;
  button {
    width: 100%;
  }
  @media (max-width: 1120px) {
    width: 100%;
    padding: 24px;
  }
`;

export const RightBlockHeader = styled.h2`
  color: #5784f7;
  font-size: 12px;
  font-weight: 700;
  line-height: 120%; /* 14.4px */
  letter-spacing: 2.4px;
`;

export const RightBlockDesc = styled.h2`
  color: #292d32;
  font-size: 32px;
  font-weight: 700;
  @media (max-width: 1120px) {
    font-size: 20px;
  }
`;

export const RightBlockDescTwo = styled.h5`
  color: #292d32;
  font-size: 16px;
  font-weight: 400;
`;

export const RightBlockForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const InputCont = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;

  div {
    flex: 1; /* Каждый input занимает равное количество места */
    width: 100%; /* Это гарантирует, что flex: 1 работает корректно */
  }

  .input-error {
    border: 1px solid red;
  }
`;

export const InputMaskCustomBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

export const InputMaskCustom = styled(InputMask)`
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #f8f9fd;
  color: #292d32;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  background: #f8f9fd;
  &:focus {
    outline: none;
    border-color: #5784f7; /* Замените на нужный цвет, если требуется */
  }
`;

export const ErrorMessage = styled.span`
  color: ${colors.WARNING};
  font-size: 12px;
  font-weight: 400;
`;

export const TextareaCustom = styled.textarea`
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #f8f9fd;
  color: #292d32;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  background: #f8f9fd;
  resize: none; /* Убираем возможность изменения размера */
  height: 98px; /* Фиксированная высота */

  &:focus {
    outline: none;
    border-color: #5784f7; /* Замените на нужный цвет, если требуется */
  }

  &.input-error {
    border-color: ${colors.WARNING};
  }
`;
