import React, { FC } from 'react';
import Container from 'index.style';
import {
  LeftBlock,
  LeftBlockHeader,
  LeftBlockTag,
  LeftBlockText,
  MainBannerBox,
  RightBlock,
  Highlight,
  ButtonBox,
  VideoWrapper,
  ImageWrapper,
  Description,
} from './MainBanner.style';
import { Button } from 'components/common/Button';
import { default as ArrowRightUpIcon } from 'assets/img/ArrowRightUpIcon.svg';
import { useModal } from 'context/ModalContext';

interface MainBannerProps {
  tagText?: string;
  headerLine1?: string;
  headerHighlight1?: string;
  headerLine2?: string;
  headerHighlight2?: string;
  firstButtonText?: string;
  secondButtonText?: string;
  videoSrc?: string;
  imageSrc?: string;
  description?: string;
}

export const MainBanner: FC<MainBannerProps> = ({
  tagText = 'Полностью прозрачные условия',
  headerLine1 = 'Легкий доступ к капиталу для',
  headerHighlight1 = 'предпринимателей',
  headerLine2 = 'Новые возможности для',
  headerHighlight2 = 'инвесторов',
  firstButtonText = 'Получить финансирование',
  secondButtonText = 'Получить бесплатное руководство',
  videoSrc,
  imageSrc,
  description,
}) => {
  const { openModal } = useModal();

  return (
    <Container>
      <MainBannerBox>
        <LeftBlock>
          <LeftBlockText>
            <LeftBlockTag>
              <p>{tagText}</p>
              <img src={ArrowRightUpIcon} alt='iconArrow' />
            </LeftBlockTag>
            <LeftBlockHeader>
              {headerLine1} <Highlight>{headerHighlight1}</Highlight>
              <br />
              {headerLine2} <Highlight>{headerHighlight2}</Highlight>
              {description && <Description>{description}</Description>}
            </LeftBlockHeader>
          </LeftBlockText>
          <ButtonBox>
            {firstButtonText && (
              <a href={'https://cabinet.lend.kz/'} rel='noopener noreferrer'>
                <Button text={firstButtonText} />
              </a>
            )}
            {secondButtonText && (
              <Button text={secondButtonText} className='white' onClick={() => openModal(2)} />
            )}
          </ButtonBox>
        </LeftBlock>
        <RightBlock>
          {videoSrc ? (
            <VideoWrapper>
              <video src={videoSrc} autoPlay loop muted playsInline>
                Ваш браузер не поддерживает воспроизведение видео.
              </video>
            </VideoWrapper>
          ) : imageSrc ? (
            <ImageWrapper>
              <img src={imageSrc} alt='Main banner' />
            </ImageWrapper>
          ) : null}
        </RightBlock>
      </MainBannerBox>
    </Container>
  );
};
