import { MainBanner } from 'components/MainBanner';
import React from 'react';
import BrokerBanner from 'assets/img/BrokerBanner.png';
import Broker1 from 'assets/img/Broker1.webp';
import Broker2 from 'assets/img/Broker2.webp';
import Broker3 from 'assets/img/Broker3.webp';
import Broker4 from 'assets/img/Broker4.webp';
import Broker5 from 'assets/img/Broker5.webp';
import Broker6 from 'assets/img/Broker6.webp';
import HistoryFace1 from 'assets/img/FaceHistory1.webp';
import HistoryFace2 from 'assets/img/FaceHistory2.webp';
import HistoryFace3 from 'assets/img/FaceHistory3.webp';
import HistoryFace4 from 'assets/img/FaceHistory4.webp';
import HistoryFace5 from 'assets/img/FaceHistory5.webp';
import HistoryFace6 from 'assets/img/FaceHistory6.webp';
import { MainFeatures } from 'components/MainFeatures';
import { SuccessStories } from 'components/SuccessStories';
import { Subtitle } from 'components/SuccessStories/SuccessStories.style';
import { CreepingLine } from 'components/CreepingLine';
import { default as LineIcon1 } from 'assets/img/LineIcon1.svg';
import { default as LineIcon4 } from 'assets/img/LineIcon4.svg';
import { default as LineIcon5 } from 'assets/img/LineIcon5.svg';
import { default as LineIcon9 } from 'assets/img/LineIcon9.svg';
import { PresentProjects } from 'components/PresentProjects';
import { FinancingInstruments } from 'components/FinancingInstruments';
import { FAQBlock } from 'components/FAQBlock';
import { FeedbackBlock } from 'components/FeedbackBlock';
import { useTranslation } from 'react-i18next';

const BrokersScreen = () => {
  const { t } = useTranslation();

  const cardsData = [
    {
      title: t('cardsDataBroker.title1'),
      description: t('cardsDataBroker.description1'),
      image: Broker5,
    },
    {
      title: t('cardsDataBroker.title2'),
      description: t('cardsDataBroker.description2'),
      image: Broker6,
    },
    {
      title: t('cardsDataBroker.title3'),
      description: t('cardsDataBroker.description3'),
      image: Broker4,
    },
    {
      title: t('cardsDataBroker.title4'),
      description: t('cardsDataBroker.description4'),
      image: Broker2,
    },
    {
      title: t('cardsDataBroker.title5'),
      description: t('cardsDataBroker.description5'),
      image: Broker1,
    },
    {
      title: t('cardsDataBroker.title6'),
      description: t('cardsDataBroker.description6'),
      image: Broker3,
    },
  ];

  const items = [
    { text: t('itemsBroker.text1'), icon: LineIcon1 },
    { text: t('itemsBroker.text2'), icon: LineIcon5 },
    { text: t('itemsBroker.text3'), icon: LineIcon1 },
    { text: t('itemsBroker.text4'), icon: LineIcon9 },
    { text: t('itemsBroker.text5'), icon: LineIcon5 },
    { text: t('itemsBroker.text6'), icon: LineIcon4 },
    { text: t('itemsBroker.text1'), icon: LineIcon1 },
    { text: t('itemsBroker.text2'), icon: LineIcon5 },
    { text: t('itemsBroker.text3'), icon: LineIcon1 },
    { text: t('itemsBroker.text4'), icon: LineIcon9 },
    { text: t('itemsBroker.text5'), icon: LineIcon5 },
    { text: t('itemsBroker.text6'), icon: LineIcon4 },
    { text: t('itemsBroker.text1'), icon: LineIcon1 },
    { text: t('itemsBroker.text2'), icon: LineIcon5 },
  ];

  const cardsDataInstrument = [
    {
      title: t('cardsDataInstrumentBroker.title1'),
      description: t('cardsDataInstrumentBroker.description1'),
    },
    {
      title: t('cardsDataInstrumentBroker.title2'),
      description: t('cardsDataInstrumentBroker.description2'),
    },
    {
      title: t('cardsDataInstrumentBroker.title3'),
      description: t('cardsDataInstrumentBroker.description3'),
    },
    {
      title: t('cardsDataInstrumentBroker.title4'),
      description: t('cardsDataInstrumentBroker.description4'),
    },
    {
      title: t('cardsDataInstrumentBroker.title5'),
      description: t('cardsDataInstrumentBroker.description5'),
    },
    {
      title: t('cardsDataInstrumentBroker.title6'),
      description: t('cardsDataInstrumentBroker.description6'),
    },
    {
      title: t('cardsDataInstrumentBroker.title7'),
      description: t('cardsDataInstrumentBroker.description7'),
    },
    {
      title: t('cardsDataInstrumentBroker.title8'),
      description: t('cardsDataInstrumentBroker.description8'),
    },
    {
      title: t('cardsDataInstrumentBroker.title9'),
      description: t('cardsDataInstrumentBroker.description9'),
    },
    {
      title: t('cardsDataInstrumentBroker.title10'),
      description: t('cardsDataInstrumentBroker.description10'),
    },
    {
      title: t('cardsDataInstrumentBroker.title11'),
      description: t('cardsDataInstrumentBroker.description11'),
    },
    {
      title: t('cardsDataInstrumentBroker.title12'),
      description: t('cardsDataInstrumentBroker.description12'),
    },
    {
      title: t('cardsDataInstrumentBroker.title13'),
      description: t('cardsDataInstrumentBroker.description13'),
    },
    {
      title: t('cardsDataInstrumentBroker.title14'),
      description: t('cardsDataInstrumentBroker.description14'),
    },
  ];

  const cards = [
    {
      title: t('cardsDataBroker1.title1'),
      description: t('cardsDataBroker1.description1'),
      example: t('cardsDataBroker1.example1'),
    },
    {
      title: t('cardsDataBroker1.title2'),
      description: t('cardsDataBroker1.description2'),
      example: t('cardsDataBroker1.example2'),
    },
    {
      title: t('cardsDataBroker1.title3'),
      description: t('cardsDataBroker1.description3'),
      example: t('cardsDataBroker1.example3'),
    },
    {
      title: t('cardsDataBroker1.title4'),
      description: t('cardsDataBroker1.description4'),
      example: t('cardsDataBroker1.example4'),
      isDown: true, // Указываем, что это карточка нижнего ряда
    },
  ];

  const faqData = [
    {
      question: t('faqDataBrokers.question1'),
      answer: (
        <>
          {t('faqDataBrokers.answer1')}
          <ul>
            <li>
              <strong>{t('faqDataBrokers.answer1.2')}</strong>
              {t('faqDataBrokers.answer1.3')}
            </li>
            <li>
              <strong>{t('faqDataBrokers.answer1.4')}</strong>
              {t('faqDataBrokers.answer1.5')}
            </li>
          </ul>
        </>
      ),
    },
    {
      question: t('faqDataBrokers.question2'),
      answer: (
        <>
          {t('faqDataBrokers.answer2')}
          <ol>
            <li>
              <strong>{t('faqDataBrokers.answer2.2')}</strong>
              {t('faqDataBrokers.answer2.3')}
            </li>
            <li>
              <strong>{t('faqDataBrokers.answer2.4')}</strong>
              {t('faqDataBrokers.answer2.5')}
            </li>
            <li>
              <strong>{t('faqDataBrokers.answer2.6')}</strong>
              {t('faqDataBrokers.answer2.7')}
            </li>
            <li>
              <strong>{t('faqDataBrokers.answer2.8')}</strong>
              {t('faqDataBrokers.answer2.9')}
            </li>
          </ol>
        </>
      ),
    },
    {
      question: t('faqDataBrokers.question3'),
      answer: (
        <>
          <strong>{t('faqDataBrokers.answer3')}</strong>
          {t('faqDataBrokers.answer3.1')}
          <ul>
            <li>{t('faqDataBrokers.answer3.2')}</li>
            <li>{t('faqDataBrokers.answer3.3')}</li>
          </ul>
          <strong>{t('faqDataBrokers.answer3.4')}</strong>
          {t('faqDataBrokers.answer3.4.1')}
          <ul>
            <li>{t('faqDataBrokers.answer3.5')}</li>
            <li>{t('faqDataBrokers.answer3.6')}</li>
          </ul>
        </>
      ),
    },
    {
      question: t('faqDataBrokers.question4'),
      answer: (
        <>
          {t('faqDataBrokers.answer4')}
          <ol>
            <li>
              <strong>{t('faqDataBrokers.answer4.2')}</strong>
              {t('faqDataBrokers.answer4.3')}
            </li>
            <li>
              <strong>{t('faqDataBrokers.answer4.4')}</strong>
              {t('faqDataBrokers.answer4.5')}
            </li>
            <li>
              <strong>{t('faqDataBrokers.answer4.6')}</strong>
              {t('faqDataBrokers.answer4.7')}
            </li>
            <li>
              <strong>{t('faqDataBrokers.answer4.8')}</strong>
              {t('faqDataBrokers.answer4.9')}
            </li>
          </ol>
        </>
      ),
    },
    {
      question: t('faqDataBrokers.question5'),
      answer: (
        <>
          {t('faqDataBrokers.answer5')}
          <ol>
            <br />
            <li>
              <strong>{t('faqDataBrokers.answer5.1')}</strong>
              {t('faqDataBrokers.answer5.2')}
            </li>
            <li>
              <strong>{t('faqDataBrokers.answer5.3')}</strong>
              {t('faqDataBrokers.answer5.4')}
            </li>
            <li>
              <strong>{t('faqDataBrokers.answer5.5')}</strong>
              {t('faqDataBrokers.answer5.6')}
            </li>
          </ol>
          <br />
          {t('faqDataBrokers.answer5.7')}
        </>
      ),
    },
    {
      question: t('faqDataBrokers.question6'),
      answer: (
        <>
          <p>{t('faqDataBrokers.answer6.1')}</p>
          <br />
          <p>{t('faqDataBrokers.answer6.2')}</p>
          <br />
          <p>{t('faqDataBrokers.answer6.3')}</p>
        </>
      ),
    },
    {
      question: t('faqDataBrokers.question7'),
      answer: (
        <>
          {t('faqDataBrokers.answer7')}
          <ol>
            <br />
            <li>
              <strong>{t('faqDataBrokers.answer7.2')}</strong>
              <br />
              {t('faqDataBrokers.answer7.3')}
            </li>
            <li>
              <strong>{t('faqDataBrokers.answer7.4')}</strong>
              <br />
              {t('faqDataBrokers.answer7.5')}
            </li>
          </ol>
          <br />
          {t('faqDataBrokers.answer7.6')}
        </>
      ),
    },
  ];

  const storiesData = [
    {
      avatars: [HistoryFace1],
      name: t('storiesData.story1.name'),
      role: t('storiesData.story1.role'),
      title: t('storiesData.story1.title'),
      description: (
        <>
          <Subtitle>{t('storiesData.title')}</Subtitle>
          {t('storiesData.story1.description')}
        </>
      ),
      details: {
        purpose: t('storiesData.story1.details.purpose'),
        amount: t('storiesData.story1.details.amount'),
        term: t('storiesData.story1.details.term'),
        form: t('storiesData.story1.details.form'),
        profitLabel: t('storiesData.story1.details.profitLabel'),
        profitValue: t('storiesData.story1.details.profitValue'),
      },
    },
    {
      avatars: [HistoryFace2],
      name: t('storiesData.story2.name'),
      role: t('storiesData.story2.role'),
      title: t('storiesData.story2.title'),
      description: (
        <>
          <Subtitle>{t('storiesData.title')}</Subtitle>
          {t('storiesData.story2.description')}
        </>
      ),
      details: {
        purpose: t('storiesData.story2.details.purpose'),
        amount: t('storiesData.story2.details.amount'),
        term: t('storiesData.story2.details.term'),
        form: t('storiesData.story2.details.form'),
        profitLabel: t('storiesData.story2.details.profitLabel'),
        profitValue: t('storiesData.story2.details.profitValue'),
      },
    },
    {
      avatars: [HistoryFace3],
      name: t('storiesData.story3.name'),
      role: t('storiesData.story3.role'),
      title: t('storiesData.story3.title'),
      description: (
        <>
          <Subtitle>{t('storiesData.title')}</Subtitle>
          {t('storiesData.story3.description')}
        </>
      ),
      details: {
        purpose: t('storiesData.story3.details.purpose'),
        amount: t('storiesData.story3.details.amount'),
        term: t('storiesData.story3.details.term'),
        form: t('storiesData.story3.details.form'),
        profitLabel: t('storiesData.story3.details.profitLabel'),
        profitValue: t('storiesData.story3.details.profitValue'),
      },
    },
    {
      avatars: [HistoryFace4],
      name: t('storiesData.story4.name'),
      role: t('storiesData.story4.role'),
      title: t('storiesData.story4.title'),
      description: (
        <>
          <Subtitle>{t('storiesData.title')}</Subtitle>
          {t('storiesData.story4.description')}
        </>
      ),
      details: {
        purpose: t('storiesData.story4.details.purpose'),
        amount: t('storiesData.story4.details.amount'),
        term: t('storiesData.story4.details.term'),
        form: t('storiesData.story4.details.form'),
        profitLabel: t('storiesData.story4.details.profitLabel'),
        profitValue: t('storiesData.story4.details.profitValue'),
      },
    },
    {
      avatars: [HistoryFace5],
      name: t('storiesData.story5.name'),
      role: t('storiesData.story5.role'),
      title: t('storiesData.story5.title'),
      description: (
        <>
          <Subtitle>{t('storiesData.title')}</Subtitle>
          {t('storiesData.story5.description')}
        </>
      ),
      details: {
        purpose: t('storiesData.story5.details.purpose'),
        amount: t('storiesData.story5.details.amount'),
        term: t('storiesData.story5.details.term'),
        form: t('storiesData.story5.details.form'),
        profitLabel: t('storiesData.story5.details.profitLabel'),
        profitValue: t('storiesData.story5.details.profitValue'),
      },
    },
    {
      avatars: [HistoryFace6],
      name: t('storiesData.story6.name'),
      role: t('storiesData.story6.role'),
      title: t('storiesData.story6.title'),
      description: (
        <>
          <Subtitle>{t('storiesData.title')}</Subtitle>
          {t('storiesData.story6.description')}
        </>
      ),
      details: {
        purpose: t('storiesData.story6.details.purpose'),
        amount: t('storiesData.story6.details.amount'),
        term: t('storiesData.story6.details.term'),
        form: t('storiesData.story6.details.form'),
        profitLabel: t('storiesData.story6.details.profitLabel'),
        profitValue: t('storiesData.story6.details.profitValue'),
      },
    },
  ];

  return (
    <>
      <MainBanner
        tagText={t('BrokerScreen.tagText')}
        headerLine1={t('BrokerScreen.headerLine1')}
        headerHighlight1=''
        headerLine2=''
        headerHighlight2=''
        firstButtonText={t('BrokerScreen.firstButtonText')}
        secondButtonText={t('BrokerScreen.secondButtonText')}
        imageSrc={BrokerBanner}
        description={t('BrokerScreen.description')}
      />
      <div id='main-features-broker'>
        <MainFeatures
          cards={cardsData}
          defaultImage='https://via.placeholder.com/800x400?text=Default+Image'
        />
      </div>
      <div id='success-stories-broker'>
        <SuccessStories stories={storiesData} sectionTitle={t('storiesData.header')} />
      </div>
      <div id='creeping-line-broker'>
        <CreepingLine items={items} title={t('itemsBroker.title')} />
      </div>
      <div id='present-project-broker'>
        <PresentProjects title={t('cardsDataBroker1.title')} cards={cards} />
      </div>
      <div id='financing-instrument-broker'>
        <FinancingInstruments
          title={t('cardsDataInstrumentBroker.title')}
          cardsData={cardsDataInstrument}
          offsetRadius={2}
        />
      </div>
      <FAQBlock faqData={faqData} title={t('faqDataBrokers.title')} />;
      <FeedbackBlock mail={t('feedbackBlock.contacts.emailValue2')} />
    </>
  );
};

export default BrokersScreen;
