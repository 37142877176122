import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Arrow,
  DropdownContainer,
  DropdownContent,
  FlagIcon,
  SwitcherButton,
  SelectedLanguage,
} from './LanguageSwitcher.style';
import useClickOutside from 'utils/useClickOutside';
import FlagKZ from 'assets/img/FlagKZ.svg';
import FlagRU from 'assets/img/FlagRU.svg';
import FlagEN from 'assets/img/FlagEN.svg';

const languageFlags: Record<string, string> = {
  ru: FlagRU,
  kk: FlagKZ,
  en: FlagEN,
};

export const LanguageSwitcher = () => {
  const [selectLanguage, setSelectLanguage] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { i18n } = useTranslation();

  useEffect(() => {
    const detectedLanguage =
      localStorage.getItem('language') || navigator.language.split('-')[0].toLowerCase() || 'kk';

    const supportedLanguages = ['ru', 'kk', 'en'];
    const finalLanguage = supportedLanguages.includes(detectedLanguage) ? detectedLanguage : 'kk';

    i18n.changeLanguage(finalLanguage);
    setLanguage(finalLanguage);
    localStorage.setItem('language', finalLanguage);
  }, [i18n]);

  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = (newLanguage: string) => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
    setSelectLanguage(false);
  };

  const handleToggleDropdown = () => {
    setSelectLanguage(!selectLanguage);
  };

  useClickOutside(dropdownRef, () => {
    setSelectLanguage(false);
  });

  return (
    <DropdownContainer ref={dropdownRef}>
      <ul>
        <li onClick={handleToggleDropdown}>
          <SelectedLanguage>
            <FlagIcon src={languageFlags[language]} alt={language.toUpperCase()} />
            {language.toUpperCase()}
            <Arrow data-is-open={selectLanguage} />
          </SelectedLanguage>
        </li>
        <DropdownContent className={selectLanguage ? 'open' : ''}>
          <ul>
            {Object.entries(languageFlags).map(([lang, flag]) => (
              <li key={lang}>
                <SwitcherButton onClick={() => changeLanguage(lang)}>
                  <FlagIcon src={flag} alt={lang.toUpperCase()} /> {lang.toUpperCase()}
                </SwitcherButton>
              </li>
            ))}
          </ul>
        </DropdownContent>
      </ul>
    </DropdownContainer>
  );
};
