import { useState, useEffect } from 'react';
import {
  Menu,
  LogoCompany,
  DropdownContainer,
  DropdownContent,
  StyledLink,
  Arrow,
  BoxSwitcher,
  ButtonBox,
  BoxMenu,
} from './Header.style';
import { default as LogoLendKz } from 'assets/img/LogoLendKz.svg';
import { default as MenuOpen } from 'assets/img/MenuOpen.svg';
import { default as MenuClose } from 'assets/img/MenuClose.svg';

import { useLocation, useNavigate } from 'react-router-dom';
import Container from 'index.style';
import { LanguageSwitcher } from 'components/LanguageSwitcher';
import { Button } from 'components/common/Button';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t } = useTranslation();
  const currentScreen = useLocation();

  const currentPath = currentScreen.pathname;
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const handleProjectsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/#projects');
    setMenuOpen(false); // Закрываем меню после перехода
  };

  const handleTariffClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/tariff');
    setMenuOpen(false); // Закрываем меню после перехода
  };

  const handleDropdownToggle = (menuName: string) => {
    setActiveDropdown((prev) => (prev === menuName ? null : menuName));
  };

  const handleLinkClick = (event: React.MouseEvent, path: string) => {
    event.preventDefault();

    const [pathname, hash] = path.split('#');

    if (pathname && currentPath !== pathname) {
      navigate(pathname, { replace: true }); // Перейти на нужную страницу, если требуется
    }

    if (hash) {
      setTimeout(() => {
        const targetElement = document.getElementById(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        } else {
          console.warn(`Element with ID '${hash}' not found.`);
        }
      }, 100); // Задержка для рендера страницы
    }

    setMenuOpen(false);
    setActiveDropdown(null);
  };

  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : '';
  }, [menuOpen]);

  return (
    <Container>
      <Menu>
        <BoxMenu>
          <LogoCompany
            onClick={(e) => handleLinkClick(e, '/')}
            src={LogoLendKz}
            loading='eager'
            alt={t('header.altLogo')}
          />
          <ul className={menuOpen ? 'menu active' : 'menu'}>
            <li>
              <DropdownContainer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StyledLink
                    to='/business'
                    onClick={(e) => handleLinkClick(e, '/business')}
                    className={currentPath.startsWith('/business') ? 'current' : 'decoration'}>
                    {t('header.business')}
                  </StyledLink>
                  {currentPath.startsWith('/business') && (
                    <Arrow
                      data-is-open={activeDropdown === 'business'}
                      onClick={() => handleDropdownToggle('business')}
                      style={{ cursor: 'pointer', marginLeft: '8px' }}
                    />
                  )}
                </div>
                {currentPath.startsWith('/business') && activeDropdown === 'business' && (
                  <DropdownContent className='open'>
                    <ul>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#main-features-business')}
                          to='#main-features-business'>
                          {t('header.mainFeatures')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#success-stories-business')}
                          to='#success-stories-business'>
                          {t('header.successStories')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#creeping-line-business')}
                          to='#creeping-line-business'>
                          {t('header.suitableFor')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#present-project-business')}
                          to='#present-project-business'>
                          {t('header.presentProject')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#financing-instrument-business')}
                          to='#financing-instrument-business'>
                          {t('header.financingTools')}
                        </StyledLink>
                      </li>
                    </ul>
                  </DropdownContent>
                )}
              </DropdownContainer>
            </li>

            <li>
              <DropdownContainer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StyledLink
                    to='/investors'
                    onClick={(e) => handleLinkClick(e, '/investors')}
                    className={currentPath.startsWith('/investors') ? 'current' : 'decoration'}>
                    {t('header.investors')}
                  </StyledLink>
                  {currentPath.startsWith('/investors') && (
                    <Arrow
                      data-is-open={activeDropdown === 'investors'}
                      onClick={() => handleDropdownToggle('investors')}
                      style={{ cursor: 'pointer', marginLeft: '8px' }}
                    />
                  )}
                </div>
                {currentPath.startsWith('/investors') && activeDropdown === 'investors' && (
                  <DropdownContent className='open'>
                    <ul>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#main-features-investor')}
                          to='#main-features-investor'>
                          {t('header.mainFeatures')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#success-stories-investor')}
                          to='#success-stories-investor'>
                          {t('header.successStories')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#creeping-line-investor')}
                          to='#creeping-line-investor'>
                          {t('header.suitableFor')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#present-project-investor')}
                          to='#present-project-investor'>
                          {t('header.chooseProject')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#financing-instrument-investor')}
                          to='#financing-instrument-investor'>
                          {t('header.financingTools')}
                        </StyledLink>
                      </li>
                    </ul>
                  </DropdownContent>
                )}
              </DropdownContainer>
            </li>

            <li>
              <DropdownContainer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StyledLink
                    to='/brokers'
                    onClick={(e) => handleLinkClick(e, '/brokers')}
                    className={currentPath.startsWith('/brokers') ? 'current' : 'decoration'}>
                    {t('header.brokers')}
                  </StyledLink>
                  {currentPath.startsWith('/brokers') && (
                    <Arrow
                      data-is-open={activeDropdown === 'brokers'}
                      onClick={() => handleDropdownToggle('brokers')}
                      style={{ cursor: 'pointer', marginLeft: '8px' }}
                    />
                  )}
                </div>
                {currentPath.startsWith('/brokers') && activeDropdown === 'brokers' && (
                  <DropdownContent className='open'>
                    <ul>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#main-features-broker')}
                          to='#main-features-broker'>
                          {t('header.mainFeatures')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#success-stories-broker')}
                          to='#success-stories-broker'>
                          {t('header.successStories')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#creeping-line-broker')}
                          to='#creeping-line-broker'>
                          {t('header.suitableFor')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#present-project-broker')}
                          to='#present-project-broker'>
                          {t('header.chooseProject')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, '#financing-instrument-broker')}
                          to='#financing-instrument-broker'>
                          {t('header.financingTools')}
                        </StyledLink>
                      </li>
                    </ul>
                  </DropdownContent>
                )}
              </DropdownContainer>
            </li>

            <li>
              <StyledLink
                onClick={handleProjectsClick}
                className={currentPath === '/#projects' ? 'current' : 'decoration'}
                to='/#projects'>
                {t('header.projects')}
              </StyledLink>
            </li>

            <li>
              <StyledLink
                onClick={handleTariffClick}
                className={currentPath === '/tariff' ? 'current' : 'decoration'}
                to='/tariff'>
                {t('header.tariff')}
              </StyledLink>
            </li>

            <li>
              <DropdownContainer>
                <StyledLink
                  to='#'
                  onClick={() => handleDropdownToggle('information')}
                  className={activeDropdown === 'information' ? 'current' : 'decoration'}>
                  {t('header.information')}
                  <Arrow data-is-open={activeDropdown === 'information'} />
                </StyledLink>
                {activeDropdown === 'information' && (
                  <DropdownContent className='open'>
                    <ul>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, 'user_agreement')}
                          to='user_agreement'>
                          {t('header.userAgreement')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, 'public_agreement')}
                          to='public_agreement'>
                          {t('header.publicOffer')}
                        </StyledLink>
                      </li>
                      <li>
                        <StyledLink
                          onClick={(e) => handleLinkClick(e, 'risk_agreement')}
                          to='risk_agreement'>
                          {t('header.riskDeclaration')}
                        </StyledLink>
                      </li>
                    </ul>
                  </DropdownContent>
                )}
              </DropdownContainer>
            </li>
          </ul>
        </BoxMenu>

        <BoxSwitcher>
          <LanguageSwitcher />
          <ButtonBox className='buttons'>
            <a href={'https://cabinet.lend.kz/'} rel='noopener noreferrer'>
              <Button text={t('header.goToPlatform')} />
            </a>
          </ButtonBox>
        </BoxSwitcher>
        <div onClick={() => setMenuOpen(!menuOpen)} className='mobile_btn'>
          {menuOpen ? (
            <img src={MenuClose} alt={t('header.menuClose')} width={24} height={24} />
          ) : (
            <img src={MenuOpen} alt={t('header.menuOpen')} width={24} height={24} />
          )}
        </div>
      </Menu>
    </Container>
  );
};
