import { MainBanner } from 'components/MainBanner';
import React from 'react';
import BusinessBanner from 'assets/img/BusinessBanner.png';
import Business1 from 'assets/img/Business1.webp';
import Business2 from 'assets/img/Business2.webp';
import Business3 from 'assets/img/Business3.webp';
import Business4 from 'assets/img/Business4.webp';
import Business6 from 'assets/img/Business6.webp';
import { MainFeatures } from 'components/MainFeatures';
import { SuccessStories } from 'components/SuccessStories';
import { Subtitle } from 'components/SuccessStories/SuccessStories.style';
import HistoryFace1 from 'assets/img/FaceHistory1.webp';
import HistoryFace2 from 'assets/img/FaceHistory2.webp';
import HistoryFace3 from 'assets/img/FaceHistory3.webp';
import HistoryFace4 from 'assets/img/FaceHistory4.webp';
import HistoryFace5 from 'assets/img/FaceHistory5.webp';
import HistoryFace6 from 'assets/img/FaceHistory6.webp';
import { CreepingLine } from 'components/CreepingLine';
import { default as LineIcon1 } from 'assets/img/LineIcon1.svg';
import { default as LineIcon2 } from 'assets/img/LineIcon2.svg';
import { default as LineIcon3 } from 'assets/img/LineIcon3.svg';
import { default as LineIcon4 } from 'assets/img/LineIcon4.svg';
import { default as LineIcon5 } from 'assets/img/LineIcon5.svg';
import { default as LineIcon6 } from 'assets/img/LineIcon6.svg';
import { default as LineIcon7 } from 'assets/img/LineIcon7.svg';
import { default as LineIcon8 } from 'assets/img/LineIcon8.svg';
import { default as LineIcon9 } from 'assets/img/LineIcon9.svg';
import { PresentProjects } from 'components/PresentProjects';
import { FinancingInstruments } from 'components/FinancingInstruments';
import { FAQBlock } from 'components/FAQBlock';
import { FeedbackBlock } from 'components/FeedbackBlock';
import { useTranslation } from 'react-i18next';

const BusinessScreen = () => {
  const { t } = useTranslation();

  const cardsData = [
    {
      title: t('cardsDataBusiness.title1'),
      description: t('cardsDataBusiness.description1'),
      image: Business1,
    },
    {
      title: t('cardsDataBusiness.title2'),
      description: t('cardsDataBusiness.description2'),
      image: Business3,
    },
    {
      title: t('cardsDataBusiness.title3'),
      description: t('cardsDataBusiness.description3'),
      image: Business4,
    },
    {
      title: t('cardsDataBusiness.title4'),
      description: t('cardsDataBusiness.description4'),
      image: Business6,
    },
    {
      title: t('cardsDataBusiness.title5'),
      description: t('cardsDataBusiness.description5'),
      image: Business2,
    },
    {
      title: t('cardsDataBusiness.title6'),
      description: t('cardsDataBusiness.description6'),
      image: Business4,
    },
  ];

  const items = [
    { text: t('itemsBusiness.text1'), icon: LineIcon1 },
    { text: t('itemsBusiness.text2'), icon: LineIcon5 },
    { text: t('itemsBusiness.text3'), icon: LineIcon1 },
    { text: t('itemsBusiness.text4'), icon: LineIcon9 },
    { text: t('itemsBusiness.text5'), icon: LineIcon5 },
    { text: t('itemsBusiness.text6'), icon: LineIcon4 },
    { text: t('itemsBusiness.text7'), icon: LineIcon7 },
    { text: t('itemsBusiness.text8'), icon: LineIcon1 },
    { text: t('itemsBusiness.text9'), icon: LineIcon8 },
    { text: t('itemsBusiness.text10'), icon: LineIcon7 },
    { text: t('itemsBusiness.text11'), icon: LineIcon1 },
    { text: t('itemsBusiness.text12'), icon: LineIcon3 },
    { text: t('itemsBusiness.text13'), icon: LineIcon6 },
    { text: t('itemsBusiness.text14'), icon: LineIcon2 },
  ];

  const cardsDataInstrument = [
    {
      title: t('cardsDataInstrumentBusiness.title1'),
      description: t('cardsDataInstrumentBusiness.description1'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title2'),
      description: t('cardsDataInstrumentBusiness.description2'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title3'),
      description: t('cardsDataInstrumentBusiness.description3'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title4'),
      description: t('cardsDataInstrumentBusiness.description4'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title5'),
      description: t('cardsDataInstrumentBusiness.description5'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title6'),
      description: t('cardsDataInstrumentBusiness.description6'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title7'),
      description: t('cardsDataInstrumentBusiness.description7'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title8'),
      description: t('cardsDataInstrumentBusiness.description8'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title9'),
      description: t('cardsDataInstrumentBusiness.description9'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title10'),
      description: t('cardsDataInstrumentBusiness.description10'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title11'),
      description: t('cardsDataInstrumentBusiness.description11'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title12'),
      description: t('cardsDataInstrumentBusiness.description12'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title13'),
      description: t('cardsDataInstrumentBusiness.description13'),
    },
    {
      title: t('cardsDataInstrumentBusiness.title14'),
      description: t('cardsDataInstrumentBusiness.description14'),
    },
  ];

  const cards = [
    {
      title: t('cardsDataBusiness1.title1'),
      description: t('cardsDataBusiness1.description1'),
      example: t('cardsDataBusiness1.example1'),
    },
    {
      title: t('cardsDataBusiness1.title2'),
      description: t('cardsDataBusiness1.description2'),
      example: t('cardsDataBusiness1.example2'),
    },
    {
      title: t('cardsDataBusiness1.title3'),
      description: t('cardsDataBusiness1.description3'),
      example: t('cardsDataBusiness1.example3'),
    },
    {
      title: t('cardsDataBusiness1.title4'),
      description: t('cardsDataBusiness1.description4'),
      example: t('cardsDataBusiness1.example4'),
      isDown: true, // Указываем, что это карточка нижнего ряда
    },
  ];

  const faqData = [
    {
      question: t('faqDataBusiness.question1'),
      answer: (
        <>
          {t('faqDataBusiness.answer1')}
          <ul>
            <li>
              <strong>{t('faqDataBusiness.answer1.2')}</strong>
              {t('faqDataBusiness.answer1.3')}
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer1.4')}</strong>
              {t('faqDataBusiness.answer1.5')}
            </li>
          </ul>
        </>
      ),
    },
    {
      question: t('faqDataBusiness.question2'),
      answer: (
        <>
          {t('faqDataBusiness.answer2')}
          <ol>
            <li>
              <strong>{t('faqDataBusiness.answer2.2')}</strong>
              {t('faqDataBusiness.answer2.3')}
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer2.4')}</strong>
              {t('faqDataBusiness.answer2.5')}
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer2.6')}</strong>
              {t('faqDataBusiness.answer2.7')}
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer2.8')}</strong>
              {t('faqDataBusiness.answer2.9')}
            </li>
          </ol>
        </>
      ),
    },
    {
      question: t('faqDataBusiness.question3'),
      answer: (
        <>
          <strong>{t('faqDataBusiness.answer3')}</strong>
          {t('faqDataBusiness.answer3.1')}
          <ul>
            <li>{t('faqDataBusiness.answer3.2')}</li>
            <li>{t('faqDataBusiness.answer3.3')}</li>
          </ul>
          <strong>{t('faqDataBusiness.answer3.4')}</strong>
          {t('faqDataBusiness.answer3.4.1')}
          <ul>
            <li>{t('faqDataBusiness.answer3.5')}</li>
            <li>{t('faqDataBusiness.answer3.6')}</li>
          </ul>
        </>
      ),
    },
    {
      question: t('faqDataBusiness.question4'),
      answer: (
        <>
          {t('faqDataBusiness.answer4')}
          <ol>
            <li>
              <strong>{t('faqDataBusiness.answer4.2')}</strong>
              {t('faqDataBusiness.answer4.3')}
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer4.4')}</strong>
              {t('faqDataBusiness.answer4.5')}
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer4.6')}</strong>
              {t('faqDataBusiness.answer4.7')}
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer4.8')}</strong>
              {t('faqDataBusiness.answer4.9')}
            </li>
          </ol>
        </>
      ),
    },
    {
      question: t('faqDataBusiness.question5'),
      answer: (
        <>
          {t('faqDataBusiness.answer5')}
          <ol>
            <li>
              <strong>{t('faqDataBusiness.answer5.2')}</strong>
              {t('faqDataBusiness.answer5.3')}
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer5.4')}</strong>
              {t('faqDataBusiness.answer5.5')}
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer5.6')}</strong>
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer5.7')}</strong>
              {t('faqDataBusiness.answer5.8')}
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer5.9')}</strong>
              {t('faqDataBusiness.answer5.10')}
            </li>
          </ol>
        </>
      ),
    },
    {
      question: t('faqDataBusiness.question6'),
      answer: (
        <>
          {t('faqDataBusiness.answer6')}
          <ol>
            <li>
              <strong>{t('faqDataBusiness.answer6.2')}</strong>
              {t('faqDataBusiness.answer6.3')}
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer6.4')}</strong>
              {t('faqDataBusiness.answer6.5')}
            </li>
            <li>
              <strong>{t('faqDataBusiness.answer6.6')}</strong>
              {t('faqDataBusiness.answer6.7')}
            </li>
          </ol>
        </>
      ),
    },
    {
      question: t('faqDataBusiness.question7'),
      answer: (
        <>
          <strong>{t('faqDataBusiness.answer7')}</strong>
          <br />
          {t('faqDataBusiness.answer7.2')}
          <br />
          <br />
          <strong>{t('faqDataBusiness.answer7.3')}</strong>
          <br />
          {t('faqDataBusiness.answer7.4')}
        </>
      ),
    },
  ];

  const storiesData = [
    {
      avatars: [HistoryFace1],
      name: t('storiesDataBusiness.story1.name'),
      role: t('storiesDataBusiness.story1.role'),
      title: t('storiesDataBusiness.story1.title'),
      description: (
        <>
          <Subtitle>{t('storiesDataBusiness.title')}</Subtitle>
          {t('storiesDataBusiness.story1.description')}
        </>
      ),
      details: {
        purpose: t('storiesDataBusiness.story1.details.purpose'),
        amount: t('storiesDataBusiness.story1.details.amount'),
        term: t('storiesDataBusiness.story1.details.term'),
        form: t('storiesDataBusiness.story1.details.form'),
      },
    },
    {
      avatars: [HistoryFace2],
      name: t('storiesDataBusiness.story2.name'),
      role: t('storiesDataBusiness.story2.role'),
      title: t('storiesDataBusiness.story2.title'),
      description: (
        <>
          <Subtitle>{t('storiesDataBusiness.title')}</Subtitle>
          {t('storiesDataBusiness.story2.description')}
        </>
      ),
      details: {
        purpose: t('storiesDataBusiness.story2.details.purpose'),
        amount: t('storiesDataBusiness.story2.details.amount'),
        term: t('storiesDataBusiness.story2.details.term'),
        form: t('storiesDataBusiness.story2.details.form'),
      },
    },
    {
      avatars: [HistoryFace3],
      name: t('storiesDataBusiness.story3.name'),
      role: t('storiesDataBusiness.story3.role'),
      title: t('storiesDataBusiness.story3.title'),
      description: (
        <>
          <Subtitle>{t('storiesDataBusiness.title')}</Subtitle>
          {t('storiesDataBusiness.story3.description')}
        </>
      ),
      details: {
        purpose: t('storiesDataBusiness.story3.details.purpose'),
        amount: t('storiesDataBusiness.story3.details.amount'),
        term: t('storiesDataBusiness.story3.details.term'),
        form: t('storiesDataBusiness.story3.details.form'),
      },
    },
    {
      avatars: [HistoryFace4],
      name: t('storiesDataBusiness.story4.name'),
      role: t('storiesDataBusiness.story4.role'),
      title: t('storiesDataBusiness.story4.title'),
      description: (
        <>
          <Subtitle>{t('storiesDataBusiness.title')}</Subtitle>
          {t('storiesDataBusiness.story4.description')}
        </>
      ),
      details: {
        purpose: t('storiesDataBusiness.story4.details.purpose'),
        amount: t('storiesDataBusiness.story4.details.amount'),
        term: t('storiesDataBusiness.story4.details.term'),
        form: t('storiesDataBusiness.story4.details.form'),
      },
    },
    {
      avatars: [HistoryFace5],
      name: t('storiesDataBusiness.story5.name'),
      role: t('storiesDataBusiness.story5.role'),
      title: t('storiesDataBusiness.story5.title'),
      description: (
        <>
          <Subtitle>{t('storiesDataBusiness.title')}</Subtitle>
          {t('storiesDataBusiness.story5.description')}
        </>
      ),
      details: {
        purpose: t('storiesDataBusiness.story5.details.purpose'),
        amount: t('storiesDataBusiness.story5.details.amount'),
        term: t('storiesDataBusiness.story5.details.term'),
        form: t('storiesDataBusiness.story5.details.form'),
      },
    },
    {
      avatars: [HistoryFace6],
      name: t('storiesDataBusiness.story6.name'),
      role: t('storiesDataBusiness.story6.role'),
      title: t('storiesDataBusiness.story6.title'),
      description: (
        <>
          <Subtitle>{t('storiesDataBusiness.title')}</Subtitle>
          {t('storiesDataBusiness.story6.description')}
        </>
      ),
      details: {
        purpose: t('storiesDataBusiness.story6.details.purpose'),
        amount: t('storiesDataBusiness.story6.details.amount'),
        term: t('storiesDataBusiness.story6.details.term'),
        form: t('storiesDataBusiness.story6.details.form'),
      },
    },
  ];

  return (
    <>
      <MainBanner
        tagText={t('BusinessScreen.tagText')}
        headerLine1={t('BusinessScreen.headerLine1')}
        headerHighlight1=''
        headerLine2=''
        headerHighlight2=''
        firstButtonText={t('BusinessScreen.firstButtonText')}
        secondButtonText={t('BrokerScreen.secondButtonText')}
        imageSrc={BusinessBanner}
        description={t('BusinessScreen.description')}
      />
      <div id='main-features-business'>
        <MainFeatures
          cards={cardsData}
          defaultImage='https://via.placeholder.com/800x400?text=Default+Image'
        />
      </div>
      <div id='success-stories-business'>
        <SuccessStories stories={storiesData} sectionTitle={t('storiesData.header')} />
      </div>
      <div id='creeping-line-business'>
        <CreepingLine items={items} title={t('itemsBusiness.title')} />
      </div>
      <div id='present-project-business'>
        <PresentProjects title={t('cardsDataBusiness1.title')} cards={cards} />
      </div>
      <div id='financing-instrument-business'>
        <FinancingInstruments
          title={t('cardsDataInstrumentBusiness.title')}
          cardsData={cardsDataInstrument}
          offsetRadius={2}
        />
      </div>
      <FAQBlock faqData={faqData} title={t('faqDataBusiness.title')} />;
      <FeedbackBlock mail={t('feedbackBlock.contacts.emailValue')} />
    </>
  );
};

export default BusinessScreen;
